import { FieldValues } from 'react-hook-form';
import { Brand, InvertedBrand } from 'typings/api/brands';
import { JobCommandsResponse } from 'typings/api/jobRunner';
import { Group, Installation, InvertedGroup } from 'typings/api/groups';
import { Dictionary } from 'typings/common/react';
import { GroupsRow } from 'typings/entity/groups';
import { RelatedFields } from 'typings/entity/jobRunner';
import { FormFieldType, SelectOption } from 'typings/form/form';
import {
  ConfigurationGroup,
  ConfigurationPayload,
  ConfigurationTypes,
} from 'typings/api/configurations';
import { AppConfiguration } from 'typings/entity/configuration';

export const parseGroupsDataToApiData = (
  formValues: GroupsRow[] | string,
  dirtyFields: number[]
): InvertedGroup[] | undefined => {
  if (formValues && dirtyFields && dirtyFields instanceof Array) {
    const result = dirtyFields
      ?.map((index: number) => {
        const updatedValue = formValues?.[index];

        if (typeof updatedValue !== 'string' && updatedValue?.config) {
          const mappedConfig = updatedValue.config
            .map(item =>
              typeof item === 'object' ? Number(item?.value) : item
            )
            .filter(item => !!item);

          const updatedItem: Installation = {
            ...updatedValue,
            id: updatedValue.id,
            config: mappedConfig,
            category_id:
              updatedValue.category_id &&
              typeof updatedValue.category_id !== 'number'
                ? updatedValue.category_id.value
                : updatedValue.category_id ?? undefined,
            ...(updatedValue?.box_discount === null && { box_discount: 0 }),
          };

          delete updatedItem.group;

          if (
            !updatedValue.group?.is_configurable &&
            updatedItem.config?.length === 0
          ) {
            delete updatedItem.config;
          }

          const res: InvertedGroup = {
            ...updatedValue?.group,
            index,
            installations: [updatedItem],
          };

          return res;
        }
        return null;
      })
      .filter((item): item is InvertedGroup => item !== null);
    return result;
  }
};

export const parseBrandsDataToApiData = (
  formValues: Brand[],
  dirtyFields: number[]
): InvertedBrand[] => {
  const result = dirtyFields?.map(index => {
    const updatedValue = formValues?.[index];

    const brandData = {
      name: updatedValue.brand?.name || '',
      id: updatedValue.brand?.id || -1,
    };
    const mappedCarriers = updatedValue.carriers?.map(({ value }) =>
      !!value ? value : -1
    );
    const installation = {
      ...updatedValue,
      carriers: mappedCarriers,
      ecommerce_brand_shop_id:
        (updatedValue.ecommerce_brand_shop_id as SelectOption)?.value ??
        (updatedValue.ecommerce_brand_shop_id as number),
      ecommerce_brand_id:
        (updatedValue.ecommerce_brand_id as SelectOption)?.value ??
        (updatedValue.ecommerce_brand_id as number),
    };

    delete installation.brand;
    const invertedBrand: InvertedBrand = {
      ...brandData,
      index,
      installations: [installation],
    };
    return invertedBrand;
  });

  return result;
};

export const parseJobRunnerResponseToSelectList = (
  data: JobCommandsResponse[]
) => {
  return data.map(({ call, parameters }, idx) => {
    const relatedFields = parseRelatedFields(parameters);

    return { label: call, value: idx, relatedFields };
  });
};

const parseRelatedFields = (
  data: Dictionary<Dictionary<string>>
): RelatedFields[] => {
  return Object.keys(data).map(key => {
    const item = data[key];
    return {
      name: key,
      fields: Object.keys(item).map(key => ({
        label: item[key],
        value: item instanceof Array ? 0 : parseInt(key),
      })),
    };
  });
};

export const parseConfigurationsToGroups = (
  configs: AppConfiguration[]
): Dictionary<AppConfiguration[]> =>
  configs.reduce(
    (acc, item) => ({
      ...acc,
      [item.configuration.group]: [
        ...(acc[item.configuration.group as keyof typeof acc] || []),
        item,
      ],
    }),
    {}
  );

export const attachValuesToConfigurationsData = (
  configurations: ConfigurationGroup[],
  params: Dictionary<SelectOption[]>
): AppConfiguration[] => {
  const mappedConfig: AppConfiguration[] = configurations?.map(config => {
    const { configuration, value, type } = config;
    const paramsArray = params[configuration.function as keyof typeof params];
    const fieldType = attachFieldType(type, configuration.function);

    if (type === 'select' || (type === 'int' && configuration.function)) {
      const selectValue = paramsArray?.find(item =>
        typeof item.value === 'number'
          ? item.value === parseInt(value as string)
          : item.label === value
      );

      return {
        ...config,
        value: selectValue || null,
        type: fieldType,
      };
    }
    if (type === 'multi') {
      const selectValues: SelectOption[] = (value as number[])
        .map(id => paramsArray?.find(item => item.value === id))
        .filter((item): item is SelectOption => !!item);
      return {
        ...config,
        value: selectValues || [],
        type: fieldType,
      };
    }
    if (type === 'nested') {
      return {
        ...config,
        type: 'nested',
        value: (value as Dictionary<string>[])?.map(item => {
          const [field] = Object.entries(item);
          const itemLabel = params['carriers'].find(
            item => item.value?.toString() === field[0]
          )?.label;
          return {
            [`${field[0]}`]: field[1],
            label: itemLabel || `(${field[0]})`,
          };
        }),
      };
    }
    if (type === 'multi-tag') {
      const tags = (value as string[]).map(label => ({
        label,
        value: Math.random(),
      }));
      return {
        ...config,
        value: tags,
        type: fieldType,
      };
    }
    return {
      ...config,
      type: fieldType,
    } as AppConfiguration;
  });
  return mappedConfig;
};

const attachFieldType = (
  apiType: ConfigurationTypes,
  func: string | null = ''
): FormFieldType => {
  if (apiType === 'array' || apiType === 'nested' || apiType === 'multi') {
    return 'multiselect';
  }
  if (apiType === 'multi-tag') {
    return 'creatableMulti';
  }
  if (apiType === 'select' || (apiType === 'int' && func)) {
    return 'select';
  }
  if (apiType === 'int') {
    return 'number';
  }
  if (apiType === 'bool') {
    return 'checkbox';
  }
  return 'text';
};

export const assembleConfigurationsPayload = (
  originalData: ConfigurationGroup[],
  formValues: FieldValues
): ConfigurationPayload[] => {
  return originalData.map(
    ({ configuration: { code, function: func }, type, id }) => {
      const payloadItem = {
        value: formValues[code],
        name: code,
        type: type,
        id: id,
      };
      if (type === 'multi-tag') {
        payloadItem.value = formValues[code]?.map(
          ({ label }: SelectOption) => label
        );
      }
      if (type === 'select' || (type === 'int' && func)) {
        payloadItem.value = formValues[code]?.value;
      }
      if (type === 'multi') {
        payloadItem.value = formValues[code]?.map(
          (item: SelectOption) => item?.value
        );
      }
      if (type === 'nested') {
        const keyValuePairs = Object.entries(formValues['nested']);
        const values = keyValuePairs.map(([key, value]) => ({
          [key]: value,
          name: `nested.${key}`,
        }));
        payloadItem.value = values;
      }

      return payloadItem;
    }
  );
};

export const parseGroupsToTableData = (groups: Group[]): Group[] => {
  return groups.map(item => ({
    ...item,
    has_box: !!item.has_box,
    is_active: !!item.is_active,
    group: {
      ...item.group,
      is_alcohol: !!item.group.is_alcohol,
      is_configurable: !!item.group.is_configurable,
    },
  }));
};

export const parseBrandsToTableData = (
  brands: Brand[],
  carriers: SelectOption[]
): Brand[] => {
  return brands.map(item => ({
    ...item,
    is_active: !!item.is_active,
    carriers: item.carriers
      ? item.carriers
          .map(
            id =>
              carriers?.find(({ value }) => id === value) || {
                label: `N/A (${id})`,
                value: id,
              }
          )
          .filter((item): item is SelectOption => !!item)
      : [],
  }));
};
